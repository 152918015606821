@keyframes fade-in {
    0%  {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fade-out {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
@keyframes float-up {
    0% {
        transform: translate(0px,50px);
    }
    100% {
        transform: translate(0px);
    }
}
@keyframes float-down {
    0% {
        transform: translate(0px);
    }
    100% {
        transform: translate(0px,50px);
    }
}
@keyframes hover-up-down {
    0% {
        transform: translate(0px);
    } 
    50% {
        transform: translate(0px, -10px);
    }
    100% {
        transform: translate(0px);
    }
}