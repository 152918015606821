$layout-tablet-start: 1126px;
$layout-mobile-start: 480px;
$background-color: #2D3142;
$background-color-secondary: #3B4357;
$text-color-primary: #ffffff;
$text-color-secondary: #BFC0C0;
$text-font-primary: 'Koulen';
$text-font-secondary: 'Roboto';
$accent-color: #EF8254;

button {
    background: none;
    border-color: white;
    border-radius: 5px;
    border-width: initial;
    width: 100%;
    padding: 10px 0px;
    border-style: solid;
    font-family: unset;
    font-size: 2.25ch;
    transition: 200ms;
    &:hover {
        cursor: pointer;
        background-color: white;
        color: $background-color;
        transition: 200ms;
    }
    &:active {
        background-color: rgb(231, 231, 229);
    }
}

html { scroll-behavior: smooth; }

