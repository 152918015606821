@import '../../main.scss';
@import '../../animations.scss';

#landing {
    background-color: $background-color;
    height: 100vh;
    overflow: hidden;
    box-shadow: inset 0px -5px 8px 0px rgba(0, 0, 0, 0.25);
}
#landing-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 93%;
    position: relative;
    z-index: 4;

    @media screen and (max-width: $layout-tablet-start) {
        display: none;
    }
}
.landing-name {
    display: flex;
    height: 300px;
    animation: fade-in 1.5s, float-up 1s;
}
.landing-name-first h1 {
    font-size: 24em;
    line-height: 1.75ch;
    
}
.landing-name-last {
    height: fit-content;
}
.landing-name-last h2 {
/* C H A M P I O N */
    text-orientation: upright;
    writing-mode: vertical-lr;
    letter-spacing: -22px;
    font-size: 2.05em;
}
.landing-info {
    padding-right: 21px;
}
.landing-info ul {
    margin-left: 1px;
    display: flex;
    list-style-type: none;
    justify-content: space-between;
    font-size: 1.80rem;
    animation: fade-in 1.5s , float-up 1s;
    li {
        color: $text-color-secondary;
        transition: 0.3s;
    }
    .landing-info-text:hover {
        color: $text-color-primary;
    }
}
.landing-icons ul {
    display: flex;
    list-style-type: none;
    margin-left: 1px;
    font-size: 2em;
    gap: 100px;
    margin-left: 6px;
    animation: fade-in 1.5s , float-up 1s;
    path {
        fill: $text-color-secondary !important;
        transition: 0.3s;
    }
    li:hover {
        path {
            fill: $text-color-primary !important;
        }
    }
}
.landing-image-container { 
    display: flex;
    align-items: center;
    width: 350px;
    justify-content: center;
    animation: fade-in 1.5s , float-up 1s;

    .landing-image {
        font-size: 260px;
        -webkit-animation: fade 5s linear forwards;
        animation: fade 5s linear forwards;
        path {
            fill: #4d5970;
        }
    }
    .image-visible {
        display: block;
    }
    .image-invisible {
        display: none;
    }
}
.landing-continue-icon {
    font-size: 2em;
    text-align: center;
    height: 7%;
    position: relative;
    z-index: 11;
    div {
        :hover {
            cursor: pointer;
        }
    }
    svg {
        animation: hover-up-down 3s ease-in-out infinite;
    }
}


#landing-container-tablet {
    display: none;

    @media screen and (max-width: $layout-tablet-start) {
        display: flex;
        position: relative;
        z-index: 4;
        height: 93%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    @media (max-width: $layout-tablet-start) and (min-width: $layout-mobile-start) {
        .landing-name-first-mobile h1 {
            font-size: 23vw;
            line-height: 0.75em;
        }
    
        .landing-name-last-mobile h2 {
            font-size: 12.5vw;
            line-height: 1em;
        }
        
        .Typewriter {
            font-size: 4.5vw;
        }

        .landing-info-button {
            width: 16.50vw;
            height: 16.50vw;

            .landing-info-button-icon {
                font-size: 7vw;
            }

            .landing-info-button-text h4 {
                font-size: 3.35vw;
            }
        }
    }

    @media (max-width: $layout-mobile-start) {
        .landing-name-first-mobile h1 {
            font-size: 14ch;
            line-height: 0.75em;    
        }
    
        .landing-name-last-mobile h2 {
            font-size: 7.5ch;
            line-height: 1em;
        }
        
        .Typewriter {
            font-size: 2.75ch;
        }
        
        .landing-info-button {
            width: 80px;
            height: 80px;

            .landing-info-button-icon {
                font-size: 2.10em;
            }

            .landing-info-button-text h4 {
                font-size: 16px;
            }
        }
    }
}

.landing-info-buttons-container {
    display: flex;
    margin-top: 20px;
}

.landing-info-button {

    background-color: $text-color-primary;
    border: none;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    &:nth-of-type(2) {
        margin-left: 15px;
        margin-right: 15px;
    }

    path {
        fill: $background-color;
    }

    .landing-info-button-text h4 {
        color: $background-color;
        font-family: 'Koulen', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    }
}


@keyframes ray-one-animation {
    0% {
        left: -158px;
    }

    50% {
        left: -138px;
    }

    100% {
        left: -158px;
    }
}
@keyframes ray-two-animation {
    0% {
        left: -100px;
    }

    50% {
        left: -80px;
    }

    100% {
        left: -100px;
    }
}
@keyframes ray-three-animation {
    0% {
        left: -40px;
    }

    50% {
        left: -20px;
    }

    100% {
        left: -40px;
    }
}

@keyframes fade {
    0%,100% { opacity: 0 }
    25%,75% { opacity: 1 }
}

.ray-one {
    position: relative;
    height: 123vh;
    left: -158px;
    bottom: 111vh;
    width: 33vw;
    background: #4C5970;
    transform: rotate(347deg);
    z-index: 3;
    animation-name: ray-one-animation;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-delay: 100ms;
}
.ray-two {
    position: relative;
    height: 123vh;
    left: -100px;
    bottom: 234vh;
    width: 33vw;
    background: #475369;
    transform: rotate(347deg);
    z-index: 2;
    animation-name: ray-two-animation;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-delay: 500ms;
}
.ray-three {
    position: relative;
    height: 123vh;
    left: -40px;
    bottom: 357vh;
    width: 33vw;
    background: #3C465A;
    transform: rotate(347deg);
    z-index: 1;
    animation-name: ray-three-animation;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-delay: 1000ms;
}