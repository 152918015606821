@import '../../main.scss';

#contact-modal-background {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: rgb(0, 0, 0, 0.50);
    z-index: 1000;
    display: flex;
}
#contact-modal-card {
    width: 400px;
    background: linear-gradient(224deg, #3B4357 31.74%, #2D3142 55.33%);
    border-radius: 15px;
    margin: auto;
    .contact-modal-image-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        h2 {
            font-weight: 200;
        }
        h4 {
            font-family: $text-font-secondary, 'sans-serif';
            font-weight: 400;
        }
        .contact-modal-image {
            border-radius: 100%;
            overflow: hidden;
            height: 100px;
            width: 100px;
            img {
                position: relative;
                top: -55px;
            }
        }
    }
}
#contact-modal-content {
    padding: 25px 45px;
    button {
        background-color: #fff;
        color: $background-color;
        transition: 250ms;
        border-width: 0;
        padding: 15px 0px;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

        &:hover {
            background-color: rgb(231, 231, 229);
            transition: 250ms;
        }
    }
    .contact-modal-link:not(:last-child) {
        margin-bottom: 15px;
    }
}