@import '../../main.scss';
@import '../../animations.scss';

#about-me {
    background-color: $background-color-secondary;
}

#about-me-container {
    padding: 56px 10px 56px 10px;
    display: grid;
    justify-content: center;
    justify-items: center;
}

#about-me-title > h2 {
    font-size: 11ch;
}

#about-me-content {
    display: flex;

    .about-me-content-image-box {
        overflow: hidden;
        position: relative;
        box-shadow: 0px 6px 8.3px rgba(0, 0, 0, 0.25);
        border-radius: 5px;

        .about-me-content-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .about-me-content-info-box {
        background-color: #282E3D;
        border-radius: 5px;
        max-width: 500px;
        height: 638px;
        box-shadow: 0px 6px 8.3px rgba(0, 0, 0, 0.25);
        
        .about-me-content-info {
            height: 100%;
            display: grid;
            grid-template-columns: 2.2fr;
            grid-template-rows: 0.1fr 2.1fr 0.1fr;
            h4 {
                font-size: 7ch;
                line-height: 2.5ch;
                font-weight: lighter;
            }
            .about-me-content-info-text {
                margin-bottom: 15px;
                p {
                    font-family: $text-font-secondary, 'sans-serif';
                    font-size: 2.20ch;
                    margin-bottom: 10px;
                    font-weight: 300;
                }
            }
            .about-me-content-info-buttons-container {
                display: flex;
                gap: 10px;
                button:active {
                    color: #282E3D;
                }
            }
        }
    }
}

@media screen and (min-width: $layout-tablet-start) {
    #about-me-content {
        flex-direction: row;
        .about-me-content-image-box {
            width: 500px;
            left: 34px;
            height: 94%;
            margin: 20px 0px;
        }
        .about-me-content-info-box {
            padding: 11px 36px 36px 53px;
            .about-me-content-info {
            }
        }
    }
}
@media (max-width: $layout-tablet-start) and (min-width: $layout-mobile-start) {
    #about-me-content {
        flex-direction: column;
        .about-me-content-image-box {
            height: 264px;
            max-width: 473.422px;
            top: 15px;
            margin: auto;
        }
        .about-me-content-info-box {
            padding: 36px 36px 36px 36px;
            height: fit-content;
            .about-me-content-info {
            }
        }
    }

}
@media (max-width: $layout-mobile-start) {
    #about-me-content {
        flex-direction: column;
        .about-me-content-image-box {
            height: 150px;
            width: 150px;
            border-radius: 100%;
            top: 40px;
            margin: -25px auto;
        }
        .about-me-content-info-box {
            padding: 68px 36px 36px 36px;
            height: fit-content;
            .about-me-content-info {
                h4 {
                    font-size: 11.5vw;
                }
            }
            .about-me-content-info-buttons-container {
                flex-direction: column;
            }
        }
    }
}