@import '../../main.scss';

#navbar {
    position: fixed;
    z-index: 100;
    width: 100vw;
}
#navbar-container {
    padding: 10px 30px 5px;
    display: flex;
}
#navbar-logo-container {
    width: 20%;
}
.nav-background-hidden {
    background-color: transparent;
    transition: background-color 0.15s, box-shadow 0.15s;
}
.nav-background-shown {
    background-color: $background-color;
    transition: background-color 0.15s, box-shadow 0.15s;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.25);
}
#navbar-links-menu-items {
    position: fixed;
    z-index: 99;
    width: 100%;
    top: 58px;
    background-color: $background-color;
    text-align: center;
    overflow: hidden;
    font-size: 3ch;
    transition: background-color 150ms;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.25);
    .navbar-link {
        padding: 10px 0;
        width: 100%;
        cursor: pointer;
        &:hover {
            background-color: $background-color-secondary;
            transition: background-color 150ms;
        }
    }
    a {
        text-decoration: none;
        width: 100%;
    }
}
@media screen and (min-width: $layout-tablet-start) {
    #navbar-links-mobile-container {
        display: none;
    }
    #navbar-links-container {
        display: inline-flex;
        justify-content: flex-end;
        align-items: center;
        width: 80%;
        gap: 50px;
        font-size: 2.15ch;
        .navbar-link {
            cursor: pointer;
            transition: 250ms ease-in-out;
            a {
                text-decoration: none;
                display: flex;
                align-items: center;
            }
            &:hover, a:hover {
                color: $accent-color;
                transition: 250ms ease-in-out;
            }
        }
    }
}
@media (max-width:$layout-tablet-start) {
    #navbar-links-mobile-container {
        display: flex;
        width: 80%;
        justify-content: right;
        font-size: 2em;
        svg {
            cursor: pointer;
        }
    }
    #navbar-links-container {
        display: none;
    }
}
