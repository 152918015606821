@import '../../main.scss';

#experience {
    background-color: $background-color-secondary;
}
#experience-container {
    padding: 56px 10% 74px;
    display: grid;
    justify-content: center;
    justify-items: center;
}
#experience-title > h2 {
    font-size: 11ch;
}
.experience-content-container {
    display: flex;
    margin-bottom: 30px;
    h1,h2,h3,h4,h5 {
        font-weight: 400;
    }
    a {
        text-decoration: none;
        width: 690px;
    }
    .experience-content-graphic {
        margin: 0px 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        box-shadow: 0px 6px 8.3px rgba(0, 0, 0, 0.25);
        transition: transform 150ms;
        height: 250px;
        h5 {
            font-size: 3ch;
        }
        &:hover {
            transition: transform 150ms;
            transform: scale(1.01);
        }
    }
    .experience-content-text-container {
        width: 100%;
        display: grid;
        grid-template-columns: 2.2fr;
        grid-template-rows: 0.1fr 2.1fr 0.1fr;
        .experience-content-text-title {
            .experience-content-text-title-year {
                display: inline-flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                a {
                    width: unset;
                    :hover .experience-link-icon {
                        opacity: 1;
                        transition: opacity 150ms, transform 150ms;
                        transform: translate(0px);
                    }
                }
                h3 {
                    font-size: 4ch;
                    line-height: 2ch;
                }
                h5 {
                    font-size: 2ch;
                    color: $text-color-secondary;
                }
                .experience-link-icon {
                    font-size: 1ch;
                    opacity: 0;
                    transition: opacity 150ms, transform 150ms;
                    transform: translate(-10px);
                    margin-left: 5px;
                }
            }
            h4 {
                font-size: 2.5ch;
                line-height: 1.5ch;
            }
            margin-bottom: 5px;
        }
        .experience-content-text-summary {
            font-size: 2ch;
            margin-bottom: 10px;
            p {
                font-family: 'Roboto', 'sans-serif';
                font-weight: 300;
            }
        }
        .experience-content-text-tech-list {
            display: inline-flex;
            flex-wrap: wrap;
        }
    }
}

@media (max-width: $layout-tablet-start) {
    .experience-content-container {
        flex-direction: column;
        a {
            text-decoration: none;
            width: unset;
        }
        .experience-content-graphic {
            width: 78vw;
            height: 50px;
            padding: 20px;
            margin: 0 0 20px 0;
        }
    }
}

@media (max-width: $layout-mobile-start) {
    .experience-content-container {
        a {
            text-decoration: none;
            width: unset;
        }
        .experience-content-text-container {
            .experience-content-text-title {
                .experience-content-text-title-year {
                    h3 {
                        font-size: 3ch;
                    }
                    h5 {
                        font-size: 1.5ch;
                    }
                }
                h4 {
                    font-size: 2ch;
                }
            }
        }
    }
}